
function getCardToken(cardData, callback) {
    var options = {
        onSuccess: function (response) {
            // Success to get card token_id, implement as you wish here
            console.log('Success to get card token_id, response:', response);
            var token_id = response.token_id;

            console.log('This is the card token_id:', token_id);
            // Implement sending the token_id to backend to proceed to next step
            callback(response);
        },
        onFailure: function (response) {
            // Fail to get card token_id, implement as you wish here
            console.log('Fail to get card token_id, response:', response);
            callback(response);
            // you may want to implement displaying failure message to customer.
            // Also record the error message to your log, so you can review
            // what causing failure on this transaction.
        }
    };
    // trigger `getCardToken` function
    MidtransNew3ds.getCardToken(cardData, options);
}


 function reqOpen3DS(redirect_url, callback) {
    var count = 0;
    const popupModal = (function () {
        var modal = null;
        return {
            openPopup(url) {
                modal = Swal.fire({
                    html: '<iframe frameborder="0" style="height:90vh; width:100%;" src="' + url + '"></iframe>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    allowOutsideClick:false
                });
            },
            closePopup() {
                Swal.close()
            }
        }
    }());

    const popupSuccess = (function () {
        var modal = null;
        return {
            openPopup() {
                modal = Swal.fire({
                    icon: 'success',
                    title:'<p><b>Pemberitahuan</b></p>',
                    html: 'Registrasi Kartu Kredit Online Dan Pendebetan Sedang Dalam Proses.' + '<br/><br/>' + 'Silahkan Tutup Halaman Ini Dan Periksa Email Anda Untuk Melihat Status Registrasi.',
                    confirmButtonColor: '#0B5ED7',
                    allowOutsideClick:false,
                    showConfirmButton:false
                  });
            },
            closePopup() {
                Swal.close()
            }
        }
    }());
    // callback functions
    var options = {
        performAuthentication: function (redirect_url) {
            // Implement how you will open iframe to display 3ds authentication redirect_url to customer
            popupModal.openPopup(redirect_url);
        },
        onSuccess: function (response) {
            // 3ds authentication success, implement payment success scenario
            if(localStorage.getItem("res") === null){
                localStorage.setItem("res", "1");  
                //callback(response);
                // popupModal.closePopup();
                // console.log('response SUKSES:', response);
                // console.log("callback");
                // popupSuccess.openPopup();
            }else{
                popupModal.closePopup();
                popupSuccess.openPopup();
               //console.log("Item exists in localstorage");
            }
            //setTimeout(function() {popupModal.closePopup();}, 600);
        },
        onFailure: function (response) {
            // 3ds authentication failure, implement payment failure scenario
            if(localStorage.getItem("res") === null){
                localStorage.setItem("res", "1");  
                //callback(response);
                // popupModal.closePopup();
                // console.log('response FAILURE:', response);
                // console.log("callback");
                //popupSuccess.openPopup();
            }else{
                popupModal.closePopup(); 
                popupSuccess.openPopup();
               //console.log("Item exists in localstorage");
            }
            // callback(response);
           
            //setTimeout(function() {popupModal.closePopup();}, 600);
        },
        onPending: function (response) {
            // transaction is pending, transaction result will be notified later via 
            // HTTP POST notification, implement as you wish here
            if(localStorage.getItem("res") === null){
                localStorage.setItem("res", "1");  
                // callback(response);
                // popupModal.closePopup();
                // console.log('response PENDING:', response);
                // console.log("callback");
                // popupSuccess.openPopup();
            }else{
                popupModal.closePopup();
                popupSuccess.openPopup();
               //console.log("Item exists in localstorage");
            }
            //setTimeout(function() {popupModal.closePopup();}, 600);
        }
    };

    // trigger `authenticate` function
    MidtransNew3ds.authenticate(redirect_url, options);
    
}
